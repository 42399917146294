import { get, post, put, Delete } from "../axios";

// 市场业绩列表-市场业绩分类（用于市场业绩列表查询和新增编辑）
export const infoMarketClassify = (params) =>
  get("/web/info/market/classify/list", params);

// 市场业绩列表-列表
export const infoMarketList = (params) => get("/web/info/market/list", params);

// 市场业绩列表-新增
export const infoMarketAdd = (params) => post("/web/info/market/add", params);

// 市场业绩列表-编辑
export const infoMarketUpdate = (params) =>
  put("/web/info/market/update", params);

// 市场业绩列表-删除
export const infoMarketDelete = (id) =>
  Delete("/web/info/market/delete/" + id, "");

// 市场业绩列表-详情
export const infoMarketView = (id) => get("/web/info/market/view/" + id, "");

// 市场业绩列表-首页推荐-状态编辑
export const infoMarketHomeUpdate = (params) =>
  put("/web/info/market/home/update", params);
