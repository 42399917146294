<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{
        `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}市场业绩`
      }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="市场业绩名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="市场业绩分类" :span="8" prop="classifyId">
        <el-select
          v-model="form.classifyId"
          clearable
          style="width: 400px"
          :disabled="disabled"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业概述" prop="performSummary">
        <el-input
          v-model="form.performSummary"
          placeholder="请输入"
          style="width: 400px"
          maxlength="500"
          :disabled="disabled"
          type="textarea"
          :rows="8"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="方案图片" :span="8" prop="image">
        <FormImgUpload
          :url="form.image"
          @upload="uploadImg"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
          :disabled="disabled"
        />
        <div style="color: #c0c1c3">
          建议尺寸：330px*186px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
        </div>
      </el-form-item>
      <!-- <el-form-item label="类型" :span="8" prop="classifyId">
        <el-radio-group
          v-model="form.type"
          @change="getType"
          :disabled="disabled"
        >
          <el-radio
            v-for="item in OTypeInfo"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item
        label="资料内容"
        :span="8"
        prop="content"
        v-if="form.type == 1"
      >
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="disabled"
        />
      </el-form-item> -->
      <el-form-item label="文件" prop="infoUrl">
        <FormFileUpload
          :url="form.infoUrl"
          @upload="upload"
          :disabled="disabled"
          :limit="1"
          :maxSize="500"
          :accept="'.pdf'"
          :multiple="true"
          :isFileName="'上传成功'"
          :showView="true"
        />
        <div style="color: #c0c1c3">文件格式：.pdf</div>
      </el-form-item>
      <!-- <el-form-item label="压缩包" prop="content" v-if="form.type == 3">
        <FormFileUpload
          :url="form.content"
          @upload="upload"
          :disabled="disabled"
          :limit="1"
          :maxSize="$store.state.user.uploadView.fileUploadMaxSize"
          :accept="'.zip,.rar'"
          :multiple="true"
          :isFileName="form.firmwareName"
          :showView="false"
        />
        <div style="color: #c0c1c3">
          软件工具等安包装请上传压缩包，文件格式：.zip,.rar，
        </div>
      </el-form-item> -->

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import {
  infoMarketClassify,
  infoMarketAdd,
  infoMarketUpdate,
  infoMarketView,
} from "@/api/info/market";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { ETypeInfo, MTypeInfo, OTypeInfo } from "@/enums/beCurrent/EBeCurrent";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
    FormImgUpload,
  },
  data() {
    return {
      ETypeInfo: ETypeInfo,
      MTypeInfo: MTypeInfo,
      OTypeInfo: OTypeInfo,
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        classifyId: "",
        name: "",
        infoUrl: [],
        image: [],
        performSummary: "",
      },
      //校验规则
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        performSummary: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        classifyId: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        infoUrl: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        // content: [
        //   {
        //     required: true,
        //     message: "请输入权益内容",
        //     trigger: "blur",
        //   },
        //   {
        //     validator(_, value) {
        //       if (value) {
        //         if (value === "<p><br></p>") {
        //           return Promise.reject(new Error("请输入内容"));
        //         }
        //         if (value.length > 65535) {
        //           return Promise.reject(new Error("内容超出最大长度限制"));
        //         }
        //       }
        //       return Promise.resolve(true);
        //     },
        //   },
        // ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    this.getClassifyList();
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await infoMarketView(this.id);
        this.form = {
          ...data,
          image: data.image.split(","),
          infoUrl: data.infoUrl.split(","),
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
        image: this.form.image.join(","),
        infoUrl: this.form.infoUrl.join(","),
        // classifyId: this.form.classifyId.join(","),
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          if (this.type == "add") {
            infoMarketAdd({
              ...newVal,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          } else {
            infoMarketUpdate({
              ...newVal,
              id: this.id,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          }
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //文件上传
    upload(e, name) {
      if (Array.isArray(e)) {
        this.form.infoUrl = [...e];
      } else {
        this.form.infoUrl = [...this.form.infoUrl, e];
      }
      // this.form.infoUrl = name;
    },
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    //权益分类
    getClassifyList() {
      infoMarketClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
    revert() {
      this.$router.go(-1);
    },
    // 切换类型
    getType(e) {
      if (e == 1) {
        this.form.content = "";
      } else {
        this.form.content = [];
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
